
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  isLoggedInLocal = false;
  sessionId = '';
  token = '';
  constructor(public authService: AuthService, private router: Router) {

  }

  get isLoggedIn$(): Observable<boolean> {

    return this.authService.isAuthenticated$.pipe(map((obj: any) => {
      console.log('this.authService.isAuthenticated$', obj)
      console.log('isLoggedIn$', this.router.url.indexOf('forms'), this.router.url)
      if (this.router.url.indexOf('forms') > -1) {
        console.log('on a forms page, so no authentication necessary')
        obj = true;
      }
      this.isLoggedInLocal = obj;


      return obj;
    }));
  }

  getToken$(): Observable<string> {
    console.log('getToken$')
    let options = {detailedResponse:true};
    return this.authService.getAccessTokenSilently(options);
  }

  get user$(): Observable<any> {
    return this.authService.user$;
  }

  login(): void {
    let host = environment.webserver;
    host =`${ window.location.protocol}//${ window.location.host}`  
 
    let returnUrl = host + '/home'

    let options: RedirectLoginOptions = {
      redirect_uri: returnUrl, appState: { target: '/dashboard' }
    };
    this.authService.loginWithRedirect(options);
  }

  logout(): void {
    console.log('logout()')
    let returnTo = environment.logoutReturn;
   let host =`${ window.location.protocol}//${ window.location.host}`  ;
    returnTo = host+'/login';
    /* { returnTo: document.location.origin } */
    this.authService.logout({ returnTo: returnTo });
  }
}